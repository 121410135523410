import clsx from 'clsx';
import React from 'react';

export interface SwitchIconProps {
  icon: JSX.Element;
  position?: 'left' | 'right' | 'top' | 'bottom';
}

export type SwitcherOptionType = {
  text?: string;
  subText?: string;
  value: string;
  icon?: SwitchIconProps;
};

export enum SwitcherVariants {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export interface SwitcherProps {
  options: SwitcherOptionType[];
  variant?: SwitcherVariants;
  value: string;
  onChange: (option: string) => void;
  size?: 'small' | 'large' | 'content';
  'aria-label': string;
}

export const Switcher: React.FC<SwitcherProps> = ({
  options,
  value,
  onChange,
  variant = SwitcherVariants.Primary,
  size = 'large',
  'aria-label': ariaLabel,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const sliderRef = React.useRef<HTMLDivElement>(null);

  let containerSizeClass = '';
  if (size !== 'content') {
    containerSizeClass =
      size === 'large'
        ? 'text-body1Medium h-[56px]'
        : 'text-body2Medium h-[46px]';
  }

  const valueIndex = options.findIndex((option) => option.value === value);
  return (
    <div
      aria-label={ariaLabel}
      role="group"
      ref={containerRef}
      className={`group relative box-border flex flex-grow rounded-[6px] border-[1px] border-neutral-300 bg-neutral-100 ${containerSizeClass}`}
    >
      <div
        className="absolute top-0 flex h-full cursor-pointer rounded-[6px] transition-transform duration-150 ease-in-out"
        style={{
          width: `calc(100% / ${options.length})`,
          transform: `translateX(calc(100% * ${valueIndex}))`,
        }}
      >
        <div
          ref={sliderRef}
          className={clsx(
            'pointer-events-none m-xs flex w-full rounded-[6px] px-l',
            {
              'border border-brandMidnight bg-brandElectric hover:bg-brandElectric-hover':
                variant === SwitcherVariants.Primary,
              'bg-brandMidnight hover:bg-brandMidnight-hover':
                variant === SwitcherVariants.Secondary,
            }
          )}
        />
      </div>
      {options.map((option, index) => {
        const checked = option.value === value;
        const icon = option?.icon?.icon || null;
        return (
          <section
            role="button"
            aria-label={`${option.text}`}
            key={`${option.value}-${index}`}
            className={clsx(
              'relative z-10 flex w-full cursor-pointer items-center justify-center whitespace-nowrap p-xs',
              {
                'flex-col':
                  option?.icon &&
                  (option.icon.position === 'top' ||
                    option.icon?.position === 'bottom'),
                'text-neutral-0':
                  checked && variant === SwitcherVariants.Secondary,
              }
            )}
            onClick={() => onChange(option.value)}
          >
            <div
              className={clsx(
                'flex h-full w-full items-center justify-center rounded-[6px]',
                {
                  'hover:bg-neutral-200': !checked,
                  'flex-col gap-s p-m text-center': option.subText,
                }
              )}
            >
              {option.icon &&
                (option.icon.position === 'left' ||
                  option.icon.position === 'top') &&
                icon}
              {option.text && (
                <div>
                  {option.text}
                  {option.subText && <div>{option.subText}</div>}
                </div>
              )}
              {option.icon &&
                option.icon.position !== 'left' &&
                option.icon.position !== 'top' &&
                icon}
            </div>
          </section>
        );
      })}
    </div>
  );
};
